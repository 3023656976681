import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Menu, Tag, Drawer, Popover } from 'antd';
import { Link } from 'react-router-dom';
import { useTour } from '@reactour/tour';
import {
  CreditCardOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  ArrowDownOutlined,
  SearchOutlined,
  DatabaseOutlined,
  DownloadOutlined,
  StopOutlined,
  BankOutlined,
  CrownOutlined,
  ShoppingOutlined,
  ContainerOutlined,
  MessageOutlined,
  AreaChartOutlined,
  SoundOutlined,
  CarOutlined,
  TeamOutlined,
  AuditOutlined,
  ControlOutlined,
  ClockCircleOutlined,
  ContactsOutlined,
  PushpinOutlined,
  StarOutlined,
  MailOutlined,
  PlusCircleOutlined,
  PictureOutlined,
  AlertOutlined,
  InboxOutlined,
  PaperClipOutlined,
  QuestionCircleOutlined,
  ShopOutlined,
  TagOutlined,
  TagsOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  BarChartOutlined,
  DashboardOutlined,
  CustomerServiceOutlined,
  ProfileOutlined,
  FileDoneOutlined,
  InfoCircleOutlined,
  RadarChartOutlined,
  AimOutlined,
  HistoryOutlined,
  FolderViewOutlined,
  DollarCircleOutlined,
  FormOutlined,
  GlobalOutlined,
  ToolOutlined,
  CalendarOutlined,
  DesktopOutlined,
  HeatMapOutlined,
  FolderOpenOutlined,
  LineChartOutlined,
  ExpandOutlined,
  ManOutlined,
  DollarOutlined,
  EllipsisOutlined,
  RocketOutlined,
  SmileOutlined,
  CalculatorOutlined,
  EyeOutlined,
  SwapOutlined,
  BlockOutlined,
  LayoutOutlined,
  FileProtectOutlined,
  ClusterOutlined,
  StockOutlined,
  UserAddOutlined
} from '@ant-design/icons';
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import SidebarDropdown from './SidebarDropdown';
import {
  collapseAtom,
  stateAtom,
  profileAtom,
  businessAtom,
  createNewBusinessAtom,
} from '../../../atoms/Atoms';
import { configSelector } from '../../../atoms/Selectors';
import envConfig from '../../../envConfig';

// const IconFont = createFromIconfontCN({
//   scriptUrl: ['//at.alicdn.com/t/font_2202999_l9anz3ql6n.js'],
// });

function Sidebar() {
  const currentState = useRecoilValue(stateAtom);
  const business = useRecoilValue(businessAtom);
  const setCreateNewBusiness = useSetRecoilState(createNewBusinessAtom);
  const [collapsed, setCollapsed] = useRecoilState(collapseAtom);
  const config = useRecoilValueLoadable(configSelector);
  const profile = useRecoilValueLoadable(profileAtom);
  const store = useRecoilValue(businessAtom);
  const isAdmin =
    profile.contents && (profile?.contents?.roles?.includes('SuperAdmin'));

  const isCustomerService =
    isAdmin ||
    (profile?.contents &&
      (profile?.contents?.roles?.includes('CustomerSupportAdmin') ||
        profile?.contents?.roles?.includes('CustomerSupport')));

  const isMarketing =
    isAdmin || (profile?.contents && profile?.contents?.roles?.includes('Marketing'));

  const isStoreAdmin =
    isAdmin || (profile?.contents && profile?.contents?.roles?.includes('StoreAdmin'));

  const isStoreManager = isStoreAdmin || profile?.contents?.roles?.includes('StoreManager');

  const hasInventtory = isStoreAdmin || profile?.contents?.roles?.includes('Inventory');

  const isDispatch = isAdmin || isStoreAdmin;

  const isDeliveryProvider =
    isAdmin || (profile?.contents && profile?.contents?.roles?.includes('DeliveryProvider'));

  const canViewFinance =
    profile.contents && (profile.contents.roles.includes('Finance') || profile.contents.roles.includes('TopAnalytics'));

  const canViewGlobalDashboard =
        isAdmin ||
        (profile.contents &&
          profile.contents.roles.includes('TopAnalytics'));

  const canViewStoreDashboard =
        isAdmin || (canViewFinance && isStoreAdmin);

  const merchandiser =
        isAdmin || isStoreAdmin || (profile.contents && profile.contents.roles.includes('Merchandiser'));

  const { t } = useTranslation();
  const { setIsOpen } = useTour();
  const width = window.innerWidth;

  function storeSelected() {
    if (store == null || store.id === -1 || store.id === -2) {
      return false;
    }
    return true;
  }
  function sidebarContent() {
    return (
      <div className="flex flex-col h-screen overflow-hidden">
        <SidebarDropdown />
        <Menu
          mode="inline"
          defaultSelectedKeys={['home']}
          className="border-0 flex-grow overflow-auto scrollbar-thin text-xs"
        >
          {canViewGlobalDashboard ? (
            <Menu.Item
              key="dashboards-submenu"
              className="text-xs"
              icon={<GlobalOutlined />}
              title="Dashboards"
            >
              {t('global_dashboard')}
              <Link to="/dashboard" />
            </Menu.Item>
          ) : null}
          {storeSelected() && canViewStoreDashboard ? (
            <Menu.Item
              key="dashboards-store"
              icon={<StarOutlined />}
              title="Store Dashboard"
            >
              {t('store_dashboard')}
              <Link to="/storedashboard" />
            </Menu.Item>
          ) : null}
          {isAdmin && (
            <>
              <Menu.SubMenu key="adminservices" icon={<ToolOutlined />} title={t('admin_services')}>
                <Menu.Item className="text-xs" key="documentacceptance" icon={<FolderOpenOutlined />}>
                  <Popover content={t('document_acceptance')}>
                    {t('document_acceptance')}
                    <Link to="/document-acceptance" />
                  </Popover>
                </Menu.Item>
                <Menu.Item className="text-xs" key="userimpersonation" icon={<UserOutlined />}>
                  <Popover content={t('impersonate_user')}>
                    {t('impersonate_user')}
                    <Link to="/impersonation" />
                  </Popover>
                </Menu.Item>
                {envConfig.REACT_APP_IMAGES_URL && width > 1024 && (
                  <Menu.Item key="imagesservice" className="text-xs" icon={<FolderViewOutlined />}>
                    {t('images_service')}
                    <Link to="/images" />
                  </Menu.Item>
                )}
                {envConfig.REACT_APP_SERVER_CONFIG && width > 1024 && (
                  <Menu.Item key="serverconfig" className="text-xs" icon={<FolderViewOutlined />}>
                    {t('server_config')}
                    <Link to="/configuration/server" />
                  </Menu.Item>
                )}
                {envConfig.REACT_APP_SERVER_CONFIG && width > 1024 && (
                  <Menu.Item key="appconfig" className="text-xs" icon={<FolderViewOutlined />}>
                    {t('app_config')}
                    <Link to="/configuration/app" />
                  </Menu.Item>
                )}
                {envConfig.REACT_APP_MANAGER_CONFIG && (
                  <Menu.Item key="managerconfig" className="text-xs" icon={<FolderViewOutlined />}>
                    {t('manager_config')}
                    <Link to="/configuration/manager" />
                  </Menu.Item>
                )}
                <Menu.Item key="businessaccounts" className="text-xs" icon={<ContactsOutlined />}>
                  <Popover content={t('business_accounts')}>
                    {t('business_accounts')}
                    <Link to="/accounts" />
                  </Popover>
                </Menu.Item>
                <Menu.Item
                  className="text-xs"
                  key="newStore"
                  icon={<PlusCircleOutlined />}
                  onClick={() => setCreateNewBusiness(true)}
                >
                  {t('new_business')}
                </Menu.Item>
                <Menu.Item className="text-xs" key="coupons" icon={<TagsOutlined />}>
                  {t('coupons')}
                  <Link to="/coupons" />
                </Menu.Item>
                <Menu.Item className="text-xs" key="cardrestrictions" icon={<StopOutlined />}>
                  {t('card_restrictions')}
                  <Link to="/cardrestrictions" />
                </Menu.Item>
                <Menu.Item className="text-xs" hidden key="userAnalytics" icon={<BarChartOutlined />}>
                  {t('user_analytics')}
                  <Link to="/userAnalytics" />
                </Menu.Item>
                <Menu.Item key="documents-admin" className="text-xs" icon={<ContainerOutlined />}>
                  {t('documents')} <Link to="/documents" />
                </Menu.Item>
                <Menu.Item className="text-xs" key="deliveryproviders" icon={<CarOutlined />}>
                  <Popover content={t('delivery_providers')}>
                    {t('delivery_providers')}
                    <Link to="/deliveryproviders" />
                  </Popover>
                </Menu.Item>
                <Menu.Item key="logs" className="text-xs" icon={<FormOutlined />}>
                  {t('logs')}
                  <Link to="/logs" />
                </Menu.Item>
              </Menu.SubMenu>
            </>
          )}
          {isCustomerService && (
            <>
              <Menu.SubMenu key="customerService" icon={<CustomerServiceOutlined />} title={t('customer_service')}>
                <Menu.Item className="text-xs" key="globalorders" icon={<FileDoneOutlined />}>
                  {t('orders')}
                  <Link to="/orders" />
                </Menu.Item>
                <Menu.Item className="text-xs" key="search-customer" icon={<UserOutlined />}>
                  {t('users')}
                  <Link to="/customers" />
                </Menu.Item>
                <Menu.Item className="text-xs" key="account_requests" icon={<UserAddOutlined />}>
                  {t('account_requests')}
                  <Link to="/customers/requests" />
                </Menu.Item>
                <Menu.Item key="referral" className="text-xs" icon={<MailOutlined />}>
                  {t('referral_email')}
                  <Link to="/referrals" />
                </Menu.Item>
                <Menu.Item key="contacts" className="text-xs" icon={<ContactsOutlined />}>
                  {t('external_contacts')}
                  <Link to="/contacts" />
                </Menu.Item>
              </Menu.SubMenu>
            </>
          )}
          {canViewFinance && (
            <Menu.Item
              key="globalpayouts"
              icon={<DollarCircleOutlined />}
            >
              <span>{t('global_payouts')}</span>
              <Link to="/globalpayouts" />
            </Menu.Item>
          )}
          {envConfig.REACT_APP_BOOKING_API_URL && (
            <Menu.SubMenu key="bookings" title={t('booking')} icon={<CalendarOutlined />}>
              <Menu.SubMenu key="maintenance" title={t('maintenance')} icon={<ControlOutlined />}>
                <Menu.Item key="bookingsubscriptions" className="text-xs" icon={<FolderViewOutlined />}>
                  <Popover content={t('subscriptions')}>
                    {t('subscriptions')}
                    <Link to="/booking/subscriptions" />
                  </Popover>
                </Menu.Item>
                <Menu.Item key="bookingevents" className="text-xs" icon={<FolderViewOutlined />}>
                  {t('events')}
                  <Link to="/booking/events" />
                </Menu.Item>
                <Menu.Item key="usersubscriptions" className="text-xs" icon={<FolderViewOutlined />}>
                  <Popover content={t('user_subscriptions')}>
                    {t('user_subscriptions')}
                    <Link to="/subscription/reports/users" />
                  </Popover>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu
                className="text-xs"
                key="allbookingreports"
                icon={<ProfileOutlined />}
                title={t('reports')}
              >
                <Menu.SubMenu
                  className="text-xs"
                  key="reservations"
                  icon={<SettingOutlined />}
                  title={t('bookings')}
                >
                  <Menu.Item key="bookingsaless" className="text-xs" icon={<FolderViewOutlined />}>
                    {t('sales')}
                    <Link to="/booking/reports/bookings" />
                  </Menu.Item>
                  <Menu.Item key="bookingshistory" className="text-xs" icon={<FolderViewOutlined />}>
                    {t('history')}
                    <Link to="/booking/reports/bookingshistory" />
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu
                  className="text-xs"
                  key="payments"
                  icon={<DollarCircleOutlined />}
                  title={
                    <Popover content={t('subscriptions')}>
                      {t('subscriptions')}
                    </Popover>
                  }
                >
                  <Menu.Item key="subscriptionsales" className="text-xs" icon={<FolderViewOutlined />}>
                    {t('sales')}
                    <Link to="/subscriptions/reports/sales" />
                  </Menu.Item>
                  <Menu.Item key="subscriptionhistory" className="text-xs" icon={<FolderViewOutlined />}>
                    {t('history')}
                    <Link to="/booking/reports/subscriptionhistory" />
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.Item key="bookingstimeslothosts" className="text-xs" icon={<FolderViewOutlined />}>
                  <Popover content={t('timeslot_hosts')}>
                    {t('timeslot_hosts')}
                    <Link to="/booking/reports/timeslot/hosts" />
                  </Popover>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu
                className="text-xs"
                key="bookingsettings"
                icon={<SettingOutlined />}
                title={t('settings')}
              >
                <Menu.Item key="bookinghosts" className="text-xs" icon={<ContactsOutlined />}>
                  {t('hosts')}
                  <Link to="/booking/hosts" />
                </Menu.Item>
                <Menu.Item key="bookinglocations" className="text-xs" icon={<CarOutlined />}>
                  {t('locations')}
                  <Link to="/booking/locations" />
                </Menu.Item>
                <Menu.Item key="servicetypes" className="text-xs" icon={<FolderOpenOutlined />}>
                  {t('service_types')}
                  <Link to="/booking/servicetypes" />
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu
                className="text-xs"
                key="heatmaps"
                icon={<HeatMapOutlined />}
                title={t('heatmaps')}
              >
                <Menu.Item className="text-xs" key="sales-heatmap">
                  {t('sales')}
                  <Link to="/booking/heatmap/sales" />
                </Menu.Item>
                <Menu.Item className="text-xs" key="booking-heatmap">
                  {t('bookings')}
                  <Link to="/booking/heatmap/bookings" />
                </Menu.Item>
              </Menu.SubMenu>
            </Menu.SubMenu>
          )}
          {canViewFinance && (
            <>
              <Menu.SubMenu key="reports" title={t('reports')} icon={<BarChartOutlined />}>
                <Menu.SubMenu className="text-xs" key="sales-group" title={t('sales')} icon={<StockOutlined />}>
                  <Menu.Item className="text-xs" key="financial-sales-reports" icon={<BlockOutlined />}>
                    {t('general')}
                    <Link to="/finance/sales" />
                  </Menu.Item>
                  <Menu.Item className="text-xs" key="financial-instore-reports" icon={<DesktopOutlined />}>
                    {t('in_store')}
                    <Link to="/finance/instore/sales" />
                  </Menu.Item>
                  <Menu.Item className="text-xs" key="financial-instore-employee-reports" icon={<TeamOutlined />}>
                    {t('in_store_employees')}
                    <Link to="/finance/instore/employees/sales" />
                  </Menu.Item>
                  <Menu.Item className="text-xs" key="financial-terminal-reports" icon={<CreditCardOutlined />}>
                    {t('settlements')}
                    <Link to="/finance/terminal" />
                  </Menu.Item>
                  <Menu.Item className="text-xs" key="financial-pos-sales-reports" icon={<ClusterOutlined />}>
                    {t('by_station')}
                    <Link to="/finance/pos/sales" />
                  </Menu.Item>
                  <Menu.Item className="text-xs" key="financial-pos-sales-summary" icon={<FileProtectOutlined />}>
                    <Popover content={t('summary')}>
                      {t('summary')}
                      <Link to="/finance/pos/summary" />
                    </Popover>
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu key="movement" title={t('movement')} icon={<SwapOutlined />}>
                  <Menu.Item className="text-xs" key="financial-division-reports" icon={<LayoutOutlined />}>
                    {t('division')}
                    <Link to="/finance/divisions" />
                  </Menu.Item>
                  <Menu.Item className="text-xs" key="financial-group-reports" icon={<LayoutOutlined />}>
                    {t('group')}
                    <Link to="/finance/departments" />
                  </Menu.Item>
                  <Menu.Item className="text-xs" key="financial-department-reports" icon={<LayoutOutlined />}>
                    {t('department')}
                    <Link to="/finance/store/departments" />
                  </Menu.Item>
                  <Menu.Item className="text-xs" key="financial-category-reports" icon={<LayoutOutlined />}>
                    {t('category')}
                    <Link to="/finance/store/categories" />
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.Item className="text-xs" key="financial-posdiscounts-reports" icon={<BlockOutlined />}>
                  {t('pos_discounts')}
                  <Link to="/finance/pos/discounts" />
                </Menu.Item>
                {business && (
                  <Menu.Item className="text-xs" key="topProducts" icon={<BankOutlined />}>
                    {t('top_products')}
                    <Link to="/topProducts" />
                  </Menu.Item>
                )}
                <Menu.SubMenu
                  className="text-xs hidden"
                  key="lost-sales"
                  icon={<RadarChartOutlined />}
                  title={t('lost_sales')}
                >
                  <Menu.Item className="text-xs" key="currentloss" icon={<AimOutlined />}>
                    {t('in_transit')}
                    <Link to="/currentloss" />
                  </Menu.Item>
                  <Menu.Item className="text-xs" key="todayloss" icon={<HistoryOutlined />}>
                    {t('today')}
                    <Link to="/todayloss" />
                  </Menu.Item>
                  <Menu.Item className="text-xs" key="yesterdayloss" icon={<HistoryOutlined />}>
                    {t('yesterday')}
                    <Link to="/yesterdayloss" />
                  </Menu.Item>
                  <Menu.Item className="text-xs" key="pastloss" icon={<DashboardOutlined />}>
                    {t('daily')}
                    <Link to="/pastloss" />
                  </Menu.Item>
                </Menu.SubMenu>
              </Menu.SubMenu>
            </>
          )}
          {isStoreAdmin && (
            <>
              <Menu.SubMenu key="storemanager" icon={<ShopOutlined />} title={t('products')}>
                <Menu.Item className="text-xs" key="itemmaster" icon={<DatabaseOutlined />}>
                  {t('catalog')}
                  <Link to="/itemmaster" />
                </Menu.Item>
                <Menu.Item className="text-xs" key="itemregistration" icon={<DatabaseOutlined />}>
                  <Popover content={t('item_registration')}>
                    {t('item_registration')}
                    <Link to="/itemregistration" />
                  </Popover>
                </Menu.Item>
                <Menu.Item className="text-xs" key="departments" icon={<DatabaseOutlined />}>
                  {t('departments')}
                  <Link to="/departments" />
                </Menu.Item>
                <Menu.Item className="text-xs" key="sections" icon={<DatabaseOutlined />}>
                  {t('categories')}
                  <Link to="/sections" />
                </Menu.Item>
                <Menu.Item className="text-xs" key="searchoptimization" icon={<SearchOutlined />}>
                  <Popover content={t('search_optimization')}>
                    {t('search_optimization')}
                    <Link to="/searchoptimization" />
                  </Popover>
                </Menu.Item>

                <Menu.Item className="text-xs" key="testconsumersearch" icon={<SearchOutlined />}>
                  <Popover content={t('consumer_search')}>
                    {t('consumer_search')}
                    <Link to="/consumersearch" />
                  </Popover>
                </Menu.Item>

                {config?.contents?.data?.hasPriceDb && (
                  <Menu.Item className="text-xs" key="itemprices" icon={<DatabaseOutlined />}>
                    {t('item_prices')}
                    <Link to="/itemprices" />
                  </Menu.Item>
                )}
                {currentState &&
                  currentState.globalReqSideAddons &&
                  currentState.managesReqSideAddons && (
                    <>
                      <Menu.Item className="text-xs" key="requirements" icon={<ShopOutlined />}>
                        {t('requirements')}
                        <Link to="/requirements" />
                      </Menu.Item>
                      <Menu.Item className="text-xs" key="sides" icon={<TagOutlined />}>
                        {t('sides')}
                        <Link to="/sides" />
                      </Menu.Item>
                      <Menu.Item className="text-xs" key="addons" icon={<TagsOutlined />}>
                        {t('add-ons')}
                        <Link to="/addons" />
                      </Menu.Item>
                    </>
                )}
              </Menu.SubMenu>
            </>
          )}
          {isMarketing && (
            <>
              <Menu.SubMenu key="marketing" icon={<StarOutlined />} title={t('marketing')}>
                <Menu.Item className="text-xs" key="infocards" icon={<AlertOutlined />}>
                  {t('info_cards')}
                  <Link to="/infocards" />
                </Menu.Item>
                <Menu.Item className="text-xs" key="sponsorships" icon={<StarOutlined />}>
                  {t('sponsorships')}
                  <Link to="/sponsorships" />
                </Menu.Item>
                <Menu.Item className="text-xs" key="globalpromotions" icon={<DollarCircleOutlined />}>
                  {t('promotions')}
                  <Link to="/promotions" />
                </Menu.Item>
                <Menu.Item className="text-xs" key="promobanners" icon={<PictureOutlined />}>
                  {t('banner_ads')}
                  <Link to="/promobanners" />
                </Menu.Item>
                <Menu.Item className="text-xs" key="promoemails" icon={<MailOutlined />}>
                  {t('promo_emails')}
                  <Link to="/promoemails" />
                </Menu.Item>
                {/* <Menu.Item className="text-xs" key="promosms" icon={<MessageOutlined />}>
                  {t('promo_sms')}
                  <Link to="/promosms" />
                </Menu.Item> */}
                <Menu.Item className="text-xs" key="productcampaigns" icon={<MailOutlined />}>
                  <Popover content={t('product_campaign')}>
                    {t('product_campaign')}
                    <Link to="/productcampaigns" />
                  </Popover>
                </Menu.Item>
                <Menu.Item className="text-xs" key="promocodes" icon={<TagsOutlined />}>
                  {t('promo_codes')}
                  <Link to="/promocodes" />
                </Menu.Item>
                <Menu.Item className="text-xs" key="pushnotifications" icon={<SoundOutlined />}>
                  <Popover content={t('push_notification')}>
                    {t('push_notification')}
                    <Link to="/pushnotifications" />
                  </Popover>
                </Menu.Item>
                <Menu.Item className="text-xs" key="pos_discounts" icon={<DatabaseOutlined />}>
                  {t('pos_discounts')}
                  <Link to="/posdiscounts" />
                </Menu.Item>
                {(isAdmin || config?.contents?.data?.data?.hasLoyalty) && (
                  <Menu.Item className="text-xs" key="loyalty" icon={<CrownOutlined />}>
                    {t('loyalty')}
                    <Link to="/loyalty" />
                  </Menu.Item>
                )}
              </Menu.SubMenu>
            </>
          )}
          {business && (
            <>
              <Menu.SubMenu
                key="store"
                icon={<ShoppingOutlined />}
                title={t('store')}
              >
                {isDispatch && (
                  <>
                    <Menu.Item className="text-xs" key="orders" icon={<FileDoneOutlined />}>
                      {t('orders')}
                      <Link to="/order-search" />
                    </Menu.Item>
                    <Menu.Item className="text-xs" key="bins" icon={<InboxOutlined />}>
                      {t('storage')}
                      <Link to="/bins" />
                    </Menu.Item>
                  </>
                )}
                {merchandiser && (
                  <>
                    <Menu.Item className="text-xs" key="merchandiser" icon={<ShoppingCartOutlined />}>
                      On Behalf
                      <Link to="/merchandiser" />
                    </Menu.Item>
                  </>
                )}
                {isStoreManager && (
                  <>
                    {config?.contents?.data?.hasPriceDb && (
                      <Menu.Item className="text-xs" key="storeitemprices" icon={<DatabaseOutlined />}>
                        {t('store_item_prices')}
                        <Link to="/storeitemprices" />
                      </Menu.Item>
                    )}
                    <Menu.Item className="text-xs" key="search-catalog" icon={<SearchOutlined />}>
                      {t('products')}
                      <Link to="/products-search" />
                    </Menu.Item>
                    <Menu.Item className="text-xs" key="staff" icon={<TeamOutlined />}>
                      {t('staff')}
                      <Link to="/team" />
                    </Menu.Item>
                    <Menu.Item className="text-xs" key="performance" icon={<LineChartOutlined />}>
                      {t('performance')}
                      <Link to="/performance" />
                    </Menu.Item>
                    {width < 1024 ? null :
                    <Menu.Item
                      className="text-xs"
                      key="sorting"
                      icon={<ArrowDownOutlined />}
                    >
                      {t('sorting')} <Tag color="#87d068">Beta</Tag>
                      <Link to="/sorting" />
                    </Menu.Item>}
                    <Menu.Item
                      className="text-xs"
                      key="messaging-store"
                      disabled
                      icon={<MessageOutlined />}
                    >
                      Chats <Tag color="#87d068">Alpha</Tag>
                      <Link to="/messaging-store" />
                    </Menu.Item>
                    <Menu.Item className="text-xs" key="loyalty02" icon={<StarOutlined />}>
                      {t('store_coupons')}
                      <Link to="/storecoupons" />
                    </Menu.Item>
                    <Menu.SubMenu key="pos" icon={<DesktopOutlined />} title={t('POS')}>
                      <Menu.Item className="text-xs" key="pos_lanes" icon={<DesktopOutlined />}>
                        {t('pos_lanes')}
                        <Link to="/pos" />
                      </Menu.Item>
                      <Menu.Item className="text-xs" key="cash_drawer_report" icon={<DesktopOutlined />}>
                        {t('cash_drawer_report')}
                        <Link to="/pos/cashdrawer/report" />
                      </Menu.Item>
                    </Menu.SubMenu>
                  </>
                )}
                {hasInventtory && (
                  <>
                    <Menu.Item className="text-xs" key="inventory" icon={<AuditOutlined />}>
                      {t('inventory')}
                      <Link to="/inventory" />
                    </Menu.Item>
                  </>
                )}
                {isDeliveryProvider && business?.locations[0]?.isDeliveryAvailable && (
                  <>
                    <Menu.Item className="text-xs" key="deliveryproviderreports" icon={<AreaChartOutlined />}>
                      {t('delivery_reports')}
                      <Link to="/deliveryproviderreports" />
                    </Menu.Item>
                    <Menu.Item className="text-xs" key="deliveries" icon={<SearchOutlined />}>
                      {t('deliveries')}
                      <Link to="/deliveries" />
                    </Menu.Item>
                  </>
                )}
                <Menu.Item key="businesslogs" className="text-xs" icon={<FormOutlined />}>
                  {t('logs')}
                  <Link to="/businesslogs" />
                </Menu.Item>
              </Menu.SubMenu>
              {business && business?.allowDelivery && (
              <>
                <Menu.SubMenu
                  key="delivery-menu"
                  icon={<ShoppingOutlined />}
                  title="3PL Providers"
                >
                  {(isDeliveryProvider || isAdmin || isStoreAdmin) && (
                  <>
                    <Menu.Item className="text-xs" key="depr" icon={<AreaChartOutlined />}>
                      {t('delivery_reports')}
                      <Link to="/deliveryproviderreports" />
                    </Menu.Item>
                    <Menu.Item className="text-xs" key="deliveries-provider" icon={<SearchOutlined />}>
                      {t('deliveries')}
                      <Link to="/deliveries" />
                    </Menu.Item>
                  </>
                  )}
                </Menu.SubMenu>
              </>
              )}
              {isStoreManager && (
                <>
                  <Menu.SubMenu
                    className="text-xs"
                    key="payments"
                    icon={<CreditCardOutlined />}
                    title={t('payments')}
                  >
                    {canViewFinance && (
                      <Menu.Item
                        className="text-xs"
                        key="payouts"
                        icon={<DollarCircleOutlined />}
                      >
                        <span>{t('payouts')}</span>
                        <Link to="/payouts" />
                      </Menu.Item>
                    )}
                    <Menu.Item className="text-xs" key="config" icon={<SettingOutlined />}>
                      {t('options')}
                      <Link to="/payment-config" />
                    </Menu.Item>
                    {/* <Menu.Item key="paypal" icon={<IconFont type="icon-paypal" />}>
                      <span>Paypal</span>
                      <Link to="/paypal" />
                    </Menu.Item>
                    <Menu.Item
                      key="ath-movil"
                      icon={<CreditCardOutlined />}
                      disabled={
                        config.state === 'loading' ||
                        (config.state === 'hasValue' &&
                          !config.contents.data.adminHasAthMovil)
                      }
                    >
                      ATH Móvil
                    </Menu.Item> */}
                  </Menu.SubMenu>
                  <Menu.SubMenu
                    key="settings"
                    className="text-xs"
                    icon={<SettingOutlined />}
                    title={t('settings')}
                  >
                    <Menu.Item className="text-xs" key="business-info" icon={<AuditOutlined />}>
                      General
                      <Link to="/business-info" />
                    </Menu.Item>
                    <Menu.Item className="text-xs" key="store-options" icon={<ControlOutlined />}>
                      {t('options')}
                      <Link to="/store-options" />
                    </Menu.Item>
                    <Menu.Item
                      className="text-xs"
                      key="settings-hours"
                      icon={<ClockCircleOutlined />}
                    >
                      {t('hours')}
                      <Link to="/hours" />
                    </Menu.Item>
                    <Menu.Item className="text-xs" key="delivery" icon={<PushpinOutlined />}>
                      {t('delivery')}
                      <Link to="/delivery" />
                    </Menu.Item>
                    <Menu.Item
                      className="text-xs"
                      key="customDepartments"
                      icon={<AppstoreAddOutlined />}
                    >
                      {t('custom_departments')}
                      <Link to="/customdepartments" />
                    </Menu.Item>
                    <Menu.Item className="text-xs" key="documents" icon={<ContainerOutlined />}>
                      {t('store_documents')} <Link to="/store-documents" />
                    </Menu.Item>
                  </Menu.SubMenu>
                </>
              )}
            </>
          )}
          <Menu.Item className="text-xs" key="ticketboards" icon={<PaperClipOutlined />}>
            {t('ticket_boards')}
            <Link to="/ticketboards" />
          </Menu.Item>
          <Menu.Item className="text-xs" key="download" icon={<DownloadOutlined />}>
            {t('downloads')}
            <Link to="/downloads" />
          </Menu.Item>
          {(config?.contents?.data?.data?.hasFaq || isAdmin) && (
            <Menu.Item key="help" icon={<QuestionCircleOutlined />}>
              {t('help')}
              <Link to="/faq" />
            </Menu.Item>
          )}
          {(isAdmin) && (
            <Menu.Item key="check_health" icon={<InfoCircleOutlined />}>
              {t('check_health')}
              <Link to="/check-health" />
            </Menu.Item>
          )}
          <Menu.Item key="tour" hidden onClick={setIsOpen(false)} icon={<InfoCircleOutlined />}>
            {t('take_tour')}
          </Menu.Item>
        </Menu>
      </div>
    );
  }

  return (
    <div>
      {width < 1024 ?
        <div>
          <Drawer
            placement="left"
            closable={false}
            maskClosable
            onClose={() => setCollapsed(false)}
            open={collapsed}
            width={275}
          >
            {sidebarContent()}
          </Drawer>
        </div> :
        <div>
          <Layout.Sider
            theme="light"
            collapsible
            collapsed={collapsed}
            onCollapse={() => setCollapsed(!collapsed)}
          >
            {sidebarContent()}
          </Layout.Sider>
        </div>}
    </div>
  );
}

export default Sidebar;
