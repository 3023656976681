import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Table,
  Input,
  Switch,
  InputNumber,
  Button,
  notification,
  Avatar,
  Tooltip
} from 'antd';
import { Comment } from '@ant-design/compatible';
import arrayMove from 'array-move';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  stateAtom,
  selectedAddonAtom,
  refreshAddonsAtom,
  selectedOptionAtom,
  businessAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function ItemGtinsDrawer() {
  const currentState = useRecoilValue(stateAtom);
  const store = useRecoilValue(businessAtom);
  const setRefreshAddons = useSetRecoilState(refreshAddonsAtom);
  const setSelectedOption = useSetRecoilState(selectedOptionAtom);
  const [selectedAddon, setSelectedAddon] = useRecoilState(selectedAddonAtom);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const submitClick = function () {
    setLoading(true);
    const temp = JSON.parse(JSON.stringify(data));
    if (temp.items) {
      for (let i = 0; i < temp.items.length; i++) {
        temp.items[i].ordering = i;
      }
    }
    api
      .post(
        'deli/addon/createorupdate',
        {
          addon: temp,
          businessId: !currentState.globalReqSideAddons && store
            ? store.id : null
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setRefreshAddons(true);
          setSelectedAddon(null);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  const deleteClick = function () {
    setLoading(true);
    api
      .delete(`deli/addon/${selectedAddon.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setRefreshAddons(true);
          setSelectedAddon(null);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  const removeOption = function (option) {
    const temp = JSON.parse(JSON.stringify(selectedAddon));
    if (option.id) {
      temp.items = _.filter(temp.items, (o) => o.id === option.id);
      api
        .post(
          `deli/delete/addonoption/${option.id}`,
          { }
        )
        .then((response) => {
          setLoading(false);
          if (response.data.success) {
            setSelectedAddon(temp);
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error);
        });
    } else {
      temp.items = _.filter(temp.items, (o) => o === option);
      setSelectedAddon(temp);
    }
  };

  function onSortEnd(index) {
    const ds = data.items;
    const temp = JSON.parse(JSON.stringify(data));
    if (index.oldIndex !== -1) {
      const newData = arrayMove(
        [].concat(ds),
        index.oldIndex,
        index.newIndex
      ).filter((el) => !!el);
      temp.items = JSON.parse(JSON.stringify(newData));
      setData(temp);
      setSelectedAddon(temp);
    }
  }

  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
  ));
  const SortableItem = sortableElement((props) => <tr {...props} />);
  const SortableContainer = sortableContainer((props) => <tbody {...props} />);
  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const dataSource = data.items;
    if (dataSource) {
      const index = dataSource.findIndex(
        (x) => x.ordering === restProps['data-row-key']
      );
      return <SortableItem index={index} {...restProps} />;
    }
    return null;
  };

  useEffect(() => {
    if (selectedAddon) {
      const temp = JSON.parse(JSON.stringify(selectedAddon));
      if (!temp.id) {
        temp.minRequired = 0;
        temp.maxRequired = 1;
      }
      setData(temp);
    } else {
      setData(selectedAddon);
    }
  }, [selectedAddon]);

  return (
    <Drawer
      title={`${selectedAddon && selectedAddon.id ? t('update') : t('create')} Add-On`}
      placement="right"
      closable
      onClose={() => setSelectedAddon(null)}
      open={selectedAddon}
      width={500}
    >
      <>
        {data && (
          <>
            {selectedAddon.id && (
              <>
                <Button
                  type="primary"
                  danger
                  loading={loading}
                  style={{
                    margin: '10px 0',
                    float: 'right'
                  }}
                  onClick={() => deleteClick()}
                >
                  {t('delete')}
                </Button>
                <br />
              </>
            )}
            <div style={{ margin: '5px 0' }}>
              <strong>{t('internal_name')}:</strong>
              <Input
                disabled={loading}
                value={data.internalName}
                placeholder={t('internal_name')}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(data));
                  temp.internalName = obj.target.value;
                  setData(temp);
                }}
              />
            </div>
            <div style={{ margin: '5px 0' }}>
              <strong>{t('name')}:</strong>
              <Input
                disabled={loading}
                value={data.name}
                placeholder={t('name')}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(data));
                  temp.name = obj.target.value;
                  setData(temp);
                }}
              />
            </div>
            <div style={{ margin: '5px 0' }}>
              <strong>{t('minimum')}: </strong>
              <InputNumber
                disabled={loading}
                defaultValue={0}
                value={data.minRequired}
                minimum={0}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(data));
                  temp.minRequired = obj;
                  setData(temp);
                }}
              />
            </div>
            <div style={{ margin: '5px 0' }}>
              <strong>{t('maximum')}: </strong>
              <InputNumber
                disabled={loading}
                defaultValue={0}
                value={data.maxRequired}
                minimum={0}
                onChange={(obj) => {
                  const temp = JSON.parse(JSON.stringify(data));
                  temp.maxRequired = obj;
                  setData(temp);
                }}
              />
            </div>
            <div className="mb-2">
              <strong>{t('amount_options_count_for_minmax')}:</strong>
              <Switch
                disabled={loading}
                checked={data.amountOptionsCountForMinMax}
                style={{ float: 'right' }}
                onChange={(checked) => {
                  setData({
                    ...data,
                    amountOptionsCountForMinMax: checked
                  });
                }}
              />
            </div>
            <strong>{t('options')}:</strong>
            <Button
              style={{ float: 'right' }}
              size="small"
              type="primary"
              onClick={() => {
                setSelectedAddon(data);
                setSelectedOption({});
              }}
            >
              {t('add_new_option')}
            </Button>
            <Table
              size="small"
              bordered
              pagination={false}
              dataSource={selectedAddon.items}
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
              columns={[
                {
                  title: t('sort'),
                  dataIndex: 'sort',
                  width: 30,
                  className: 'drag-visible',
                  render: () => <DragHandle />,
                },
                {
                  title: t('image'),
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <>
                      {row.imageUrl && row.imageUrl.length > 0 && (
                        <img
                          style={{ margin: 'auto' }}
                          width={50}
                          src={row.imageUrl}
                          alt={row.name}
                        />
                      )}
                    </>
                  ),
                },
                {
                  title: t('name'),
                  dataIndex: 'name',
                  key: 'name',
                  align: 'center',
                  className: 'text-xs',
                  render: (text) => (
                    <span>{text}</span>
                  ),
                },
                {
                  title: t('description'),
                  dataIndex: 'description',
                  key: 'description',
                  align: 'center',
                  className: 'text-xs',
                  render: (text) => (
                    <span>{text}</span>
                  ),
                },
                {
                  title: '',
                  align: 'center',
                  className: 'text-xs',
                  render: (row) => (
                    <>
                      <Button
                        size="small"
                        type="primary"
                        style={{ marginRight: 3 }}
                        onClick={() => {
                          setSelectedAddon(data);
                          setSelectedOption(row);
                        }}
                      >
                        {t('edit')}
                      </Button>
                      <Button
                        size="small"
                        type="primary"
                        danger
                        style={{ marginLeft: 3 }}
                        onClick={() => {
                          removeOption(row);
                        }}
                      >
                        {t('delete')}
                      </Button>
                    </>
                  ),
                }
              ]}
            />
            <Button
              type="primary"
              loading={loading}
              style={{
                margin: '10px 0',
                float: 'right'
              }}
              onClick={() => submitClick()}
            >
              {selectedAddon && selectedAddon.id ? 'Update' : 'Create'}
            </Button>
          </>
        )}
      </>
    </Drawer>
  );
}
export default ItemGtinsDrawer;
