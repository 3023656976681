import {
  Button,
  Table,
  Modal,
  Input,
  InputNumber,
  Tooltip,
  notification,
  Avatar,
  Image,
  Switch,
  Select
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import { useRecoilState, useRecoilValue } from 'recoil';
import { selectedInventoryItemAtom, timezoneAtom } from '../../../../atoms/Atoms';
import api from '../../../../api/api';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import { mapInventoryReasonToString, mapInventoryTypeToString, renderDate, thousand, toCurrency } from '../../../utils/functions';

function ItemHistoryModal() {
  const { Option } = Select;
  const { t } = useTranslation();
  const timezone = useRecoilValue(timezoneAtom);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [data, setData]
    = useState(null);
  const [qoh, setQoh] = useState(0);
  const [cost, setCost] = useState(0);
  const [selectedInventoryItem, setSelectedInventoryItem]
    = useRecoilState(selectedInventoryItemAtom);
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalHistory, setTotalHistory] = useState(0);
  const [widthScreen, setWidthScreen] = useState(750);
  const [showAdjustment, setShowAdjustment] = useState(false);
  const [selectedType, setSelectedType] = useState(3);
  const [selectedReason, setSelectedReason] = useState(0);
  const [comments, setComments] = useState('');
  const [amount, setAmount] = useState(0);
  const [boxes, setBoxes] = useState(0);
  const [boxUnits, setBoxUnits] = useState(0);
  const [totalUnits, setTotalUnits] = useState(0);
  const [helperAmount, setHelperAmount] = useState(0);
  const [loadingRelatedItems, setLoadingRelatedItems] = useState(false);
  const [relatedItems, setRelatedItems] = useState([]);
  const [showBreakdownModal, setShowBreakdownModal] = useState(false);
  const [loadingBreakdown, setLoadingBreakdown] = useState(false);
  const [unitItemsForBreakdown, setUnitItemsForBreakdown] = useState(0);
  const [showEditCost, setShowEditCost] = useState(false);

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function getItemHistory(page) {
    if (data) {
      setLoading(true);
      api
        .get(`inventory/history/${data.id}/${page}/${size}`)
        .then((response) => {
          setLoading(false);
          if (!response.data.success) {
            showMessage(response.data.error);
          } else {
            setTotalHistory(response.data.data.total);
            setHistory(response.data.data.result);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error);
        });
    }
  }

  function updateIsVisible(val) {
    setLoading(true);
    api
      .post('inventory/update/item', {
        BusinessItemId: data.id,
        IsVisible: val,
      })
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          setData({ ...data,
            isVisible: !val });
        } else {
          showMessage(t('updated_successfully'));
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  }

  function updateIsAvailable(val) {
    setLoading(true);
    api
      .post('inventory/update/item', {
        BusinessItemId: data.id,
        IsAvailable: val
      })
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          setData({ ...data,
            isAvailable: !val });
        } else {
          showMessage(t('updated_successfully'));
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  }

  function updateAffectsInventory(val) {
    setLoading(true);
    api
      .post('inventory/update/item', {
        BusinessItemId: data.id,
        AffectsInventory: val,
      })
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          setData({ ...data,
            affectsInventory: !val });
        } else {
          showMessage(t('updated_successfully'));
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  }

  function onTableChange(page, pageSize) {
    if (page - 1 !== currentPage) {
      setCurrentPage(page - 1);
      getItemHistory(page - 1);
    } else {
      setSize(pageSize);
    }
  }

  function resetAdjustmentModal() {
    setShowEditCost(false);
    setComments('');
    setSelectedType(3);
    setAmount(0);
    setBoxes(0);
    setBoxUnits(0);
    setHelperAmount(0);
    setSelectedReason(0);
  }

  function applyHelperAmount() {
    if (selectedInventoryItem.quantityOnHand > helperAmount) {
      setSelectedType(2);
      setAmount(selectedInventoryItem.quantityOnHand - helperAmount);
    } else {
      setSelectedType(1);
      setAmount(helperAmount - selectedInventoryItem.quantityOnHand);
    }
  }

  function applyBoxesHelper() {
    setAmount(totalUnits);
  }

  function getQoh() {
    if (data) {
      api
        .get(`inventory/item/${data.id}`)
        .then((response) => {
          if (!response.data.success) {
            showMessage(response.data.error);
            setLoading(false);
          } else {
            setQoh(response.data.data.quantityOnHand);
            setCost(response.data.data.cost);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error);
        });
    }
  }

  function submit() {
    setLoading(true);
    if (showEditCost) {
      api
        .post('inventory/update/item', {
          BusinessItemId: data.id,
          Cost: cost,
        })
        .then((response) => {
          // setLoading(false);
          // if (!response.data.success) {
          //   showMessage(response.data.error);
          // } else {
          //   showMessage(t('updated_successfully'));
          //   getQoh();
          // }
        })
        .catch((error) => {
          // setLoading(false);
          // showMessage(error);
        });
      setShowEditCost(false);
    }
    api
      .post('inventory/register', {
        BusinessItemId: data.id,
        Amount: amount,
        Comments: comments,
        Type: selectedType
      })
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        } else {
          showMessage(t('updated_successfully'));
          getQoh();
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  }

  function getRelatedItems() {
    if (data) {
      setLoadingRelatedItems(true);
      api
        .get(`inventory/related/${data?.id}`)
        .then((response) => {
          setLoadingRelatedItems(false);
          if (!response.data.success) {
            showMessage(response.data.error);
          } else {
            const tempData = [...response.data.data];
            for (let i = 0; i < tempData.length; i++) {
              tempData[i].add = 0;
              tempData[i].remove = 0;
            }
            setRelatedItems(tempData);
          }
        })
        .catch((error) => {
          setLoadingRelatedItems(false);
          showMessage(error);
        });
    }
  }

  function breakdown() {
    setLoadingBreakdown(true);
    const filtered = _.filter(relatedItems, (i) => i.add > 0 || i.remove > 0);
    const mapped = _.map(filtered, (i) => ({
      BusinessItemId: i.id,
      Remove: i.remove,
      Add: i.add
    }));
    api
      .post('inventory/breakdown', mapped)
      .then((response) => {
        setLoadingBreakdown(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        } else {
          showMessage(t('item_movements_successful'));
          setShowBreakdownModal(false);
          getQoh();
          getRelatedItems();
        }
      })
      .catch((error) => {
        setLoadingBreakdown(false);
        showMessage(error);
      });
  }

  function getUnitItemsForBreakdown() {
    let tempCount = 0;
    for (let i = 0; i < relatedItems.length; i++) {
      tempCount -= relatedItems[i].add * relatedItems[i].pack;
      tempCount += relatedItems[i].remove * relatedItems[i].pack;
    }
    return tempCount;
  }

  useEffect(() => {
    setUnitItemsForBreakdown(getUnitItemsForBreakdown());
  }, [relatedItems]);

  useEffect(() => {
    const width = windowSize.current[0];
    setWidthScreen(4 * (width / 5));
  }, [windowSize]);

  useEffect(() => {
    setShowEditCost(false);
    if (data) {
      setCurrentPage(0);
      getItemHistory(0);
      setSize(20);
      setQoh(data.quantityOnHand);
      setCost(data.cost);
      getQoh();
      if (data.hasRelatedItems) {
        getRelatedItems();
      }
    } else {
      setHistory([]);
    }
  }, [data]);

  useEffect(() => {
    setCurrentPage(0);
    getItemHistory(0);
  }, [size]);

  useEffect(() => {
    setData(selectedInventoryItem);
  }, [selectedInventoryItem]);

  useEffect(() => {
    setTotalUnits(boxes * boxUnits);
  }, [boxUnits, boxes]);

  return (
    <div>
      <Modal
        width={widthScreen}
        title={t('item_history')}
        open={data}
        onCancel={() => {
          setSelectedInventoryItem(null);
        }}
        footer={[
          <Button
            key="close"
            type="primary"
            danger
            loading={loading}
            onClick={() => {
              setSelectedInventoryItem(null);
            }}
          >
            {t('close')}
          </Button>,
          <Button
            key="adjust"
            type="primary"
            loading={loading}
            onClick={() => {
              resetAdjustmentModal();
              setShowAdjustment(true);
            }}
          >
            {t('adjust')}
          </Button>,
          <Button
            key="related"
            hidden={!data?.hasRelatedItems}
            type="primary"
            loading={loadingRelatedItems}
            disabled={!loadingRelatedItems && relatedItems.length === 0}
            onClick={() => {
              setShowBreakdownModal(true);
            }}
          >
            {data?.isUnitForRelatedItems ? t('breakdown_related') : t('breakdown')}
          </Button>
        ]}
      >
        <div className="flex">
          <div className="w-1/3">
            <Image
              className="mx-auto"
              width={150}
              src={data?.itemImage}
              alt={data?.completeName}
            />
            <div className="text-center">
              <p>{data?.upc}</p>
              <p className="text-lg">{data?.brand}</p>
              <p>{data?.name}</p>
              <p>{data?.description}</p>
            </div>
            <div>
              <span className="font-bold">{t('is_visible')}: </span>
              <Switch
                disabled={loading}
                style={{ float: 'right' }}
                checked={data?.isVisible}
                onChange={(checked) => {
                  updateIsVisible(!data?.isVisible);
                  setData({ ...data,
                    isVisible: !data.isVisible });
                }}
              />
            </div>
            <div className="mt-2">
              <span className="font-bold">{t('is_available')}: </span>
              <Switch
                disabled={loading}
                style={{ float: 'right' }}
                checked={data?.isAvailable}
                onChange={(checked) => {
                  updateIsAvailable(!data?.isAvailable);
                  setData({ ...data,
                    isVisible: !data.isAvailable });
                }}
              />
            </div>
            <div className="mt-2">
              <span className="font-bold">{t('affects_inventory')}: </span>
              <Switch
                disabled={loading}
                style={{ float: 'right' }}
                checked={data?.affectsInventory}
                onChange={(checked) => {
                  updateAffectsInventory(!data?.affectsInventory);
                  setData({ ...data,
                    isVisible: !data.affectsInventory });
                }}
              />
            </div>
            <div>
              <span className="font-bold">{t('cost')}: </span>
              <span className="float-right">{toCurrency(cost) === '-' ? '$0.00' : toCurrency(cost)}</span>
            </div>
            <div>
              <span className="font-bold">{t('quantity_on_hand')}: </span>
              <span className="float-right">{thousand(qoh)}</span>
            </div>
            <div>
              <span className="font-bold">{t('department')}: </span>
              <span className="float-right">{data?.superSectionName}</span>
            </div>
            <div>
              <span className="font-bold">{t('section')}: </span>
              <span className="float-right">{data?.sectionName}</span>
            </div>
            {data?.department && (
              <div>
                <span className="font-bold">{t('group')}: </span>
                <span className="float-right">{data?.department}</span>
              </div>
            )}
          </div>
          <div className="ml-2 w-full">
            <Table
              size="small"
              className="mt-2"
              loading={loading}
              bordered
              pagination={{
                pageSize: size,
                showSizeChanger: true,
                defaultCurrent: 0,
                current: currentPage + 1,
                total: totalHistory,
                onChange: onTableChange
              }}
              columns={[
                {
                  title: t('created_on'),
                  key: 'name',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{renderDate(text.createdAt, timezone)}</span>,
                },
                {
                  title: t('type'),
                  key: 'type',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{mapInventoryTypeToString(t, text.type)}</span>,
                },
                {
                  title: t('reason'),
                  key: 'reason',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{mapInventoryReasonToString(t, text.reason)}</span>,
                },
                {
                  title: t('amount'),
                  key: 'amount',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{thousand(text.amount)}</span>,
                },
                {
                  title: t('comments'),
                  key: 'comments',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{text.comments}</span>,
                },
                {
                  title: t('registered_by'),
                  key: 'registered_by',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{text.registeredBy.completeName}</span>,
                },
              ]}
              dataSource={history}
            />
          </div>
        </div>
      </Modal>
      <Modal
        width={widthScreen / 1.55}
        title={t('inventory_adjustment')}
        open={showAdjustment}
        onCancel={() => {
          setShowAdjustment(false);
        }}
        footer={[
          <Button
            key="close"
            type="primary"
            danger
            loading={loading}
            onClick={() => {
              setShowAdjustment(false);
            }}
          >
            {t('close')}
          </Button>,
          <Button
            key="showEditCost"
            type="primary"
            loading={loading}
            onClick={() => setShowEditCost(true)}
          >
            {t('edit_cost')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => {
              submit();
            }}
          >
            {t('submit')}
          </Button>
        ]}
      >
        <div className="flex divide-x">
          <div className="divide-y pr-2">
            <div>
              <p className="text-bold text-lg">{t('amount_calculator_helper')}</p>
              <div className="flex mb-2">
                <InputNumber
                  disabled={loading}
                  min={0}
                  step="1"
                  onChange={(obj) => {
                    setBoxes(obj);
                  }}
                  value={boxes}
                />
                <span className="text-bold text-lg mx-2"> x </span>
                <InputNumber
                  disabled={loading}
                  min={0}
                  step="1"
                  onChange={(obj) => {
                    setBoxUnits(obj);
                  }}
                  value={boxUnits}
                />
                <span className="text-bold text-lg mx-2"> = {totalUnits}</span>
                <Button
                  type="primary"
                  onClick={() => applyBoxesHelper()}
                >
                  {t('apply')}
                </Button>
              </div>
            </div>
            <div>
              <p className="text-bold text-lg">{t('set_amount_helper')}</p>
              <div className="flex">
                <InputNumber
                  disabled={loading}
                  min={0}
                  step="1"
                  onChange={(obj) => {
                    setHelperAmount(obj);
                  }}
                  value={helperAmount}
                />
                <Button
                  type="primary"
                  onClick={() => applyHelperAmount()}
                >
                  {t('apply')}
                </Button>
              </div>
            </div>
          </div>
          <div className="pl-2">
            <div className="mb-2">
              <strong>{t('type')}</strong>
              <Select
                className="w-full"
                onChange={(value) => setSelectedType(value)}
                value={selectedType}
              >
                <Option value={0}>{t('sale')}</Option>
                <Option value={1}>{t('adjustment_up')}</Option>
                <Option value={2}>{t('adjustment_down')}</Option>
                <Option value={3}>{t('receiving')}</Option>
              </Select>
            </div>
            <div className="mb-2">
              <strong>{t('reason')}</strong>
              <Select
                className="w-full"
                onChange={(value) => setSelectedReason(value)}
                value={selectedReason}
              >
                <Option value={0}>{t('none')}</Option>
                <Option value={1}>{t('damage')}</Option>
                <Option value={2}>{t('lost')}</Option>
                <Option value={3}>{t('found')}</Option>
                <Option value={4}>{t('replenishment')}</Option>
                <Option value={4}>{t('sampling')}</Option>
                <Option value={4}>{t('gift')}</Option>
                <Option value={4}>{t('spoiled')}</Option>
                <Option value={4}>{t('transfer_to_kitchen')}</Option>
                <Option value={4}>{t('transfer_to_other_store')}</Option>
              </Select>
            </div>
            <div className="mb-2">
              <strong>{t('enter_amount')}</strong>
              <InputNumber
                className="w-full"
                disabled={loading}
                min={0}
                step="1"
                onChange={(obj) => {
                  setAmount(obj);
                }}
                value={amount}
              />
            </div>
            <div className="mb-2">
              <strong>{t('comments')}</strong>
              <Input
                disabled={loading}
                className="w-full"
                value={comments}
                onChange={(obj) => {
                  setComments(obj.target.value);
                }}
              />
            </div>
            {!showEditCost && (
              <div className="mb-2 flex">
                <strong className="mr-2">{t('cost')}</strong>
                <span>{toCurrency(cost) === '-' ? '$0.00' : toCurrency(cost)}</span>
              </div>
            )}
            {showEditCost && (
              <div className="mb-2">
                <strong className="mr-2">{t('cost')}</strong>
                <InputNumber
                  addonBefore="$"
                  className="w-full"
                  disabled={loading}
                  min={0}
                  step="1"
                  onChange={(obj) => {
                    setCost(obj);
                  }}
                  value={cost}
                />
              </div>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        width={widthScreen * 0.9}
        title={t('item_breakdown')}
        open={showBreakdownModal}
        onCancel={() => {
          setShowBreakdownModal(false);
        }}
        footer={[
          <Button
            key="closebreakdown"
            type="primary"
            danger
            disabled={loadingBreakdown}
            onClick={() => {
              setShowBreakdownModal(false);
            }}
          >
            {t('close')}
          </Button>,
          <Button
            key="adjustbreakdown"
            type="primary"
            loading={loadingBreakdown}
            onClick={() => {
              breakdown();
            }}
            disabled={unitItemsForBreakdown !== 0 ||
              (_.filter(relatedItems, (i) => i.add > 0).length === 0 &&
              _.filter(relatedItems, (i) => i.remove > 0).length === 0)}
          >
            {t('adjust')}
          </Button>
        ]}
      >
        <div className="text-lg mb-2 text-center">
          {t('unit_items_left_for_distribution')}: {thousand(unitItemsForBreakdown)}
        </div>
        <Table
          size="small"
          loading={loadingBreakdown}
          bordered
          pagination={false}
          columns={[
            {
              title: t('image'),
              key: 'image',
              align: 'center',
              width: 80,
              className: 'text-xs',
              render: (text) =>
                <Image
                  width={50}
                  src={text.itemImage}
                  alt={`${text.brand} ${text.name} ${text.description}`}
                />,
            },
            {
              title: t('brand'),
              key: 'brand',
              align: 'left',
              className: 'text-xs',
              render: (text) =>
                <span>{text.brand}</span>,
            },
            {
              title: t('name'),
              key: 'name',
              align: 'left',
              className: 'text-xs',
              render: (text) =>
                <span>{text.name}</span>,
            },
            {
              title: t('description'),
              key: 'description',
              align: 'left',
              className: 'text-xs',
              render: (text) =>
                <span>{text.description}</span>,
            },
            {
              title: t('quantity_on_hand'),
              key: 'quantityOnHand',
              align: 'left',
              className: 'text-xs',
              render: (text) =>
                <span>{text.quantityOnHand}</span>,
            },
            {
              title: t('pack'),
              key: 'pack',
              align: 'left',
              className: 'text-xs',
              render: (text) =>
                <span>{text.pack}</span>,
            },
            {
              title: t('remove'),
              key: 'remove',
              align: 'center',
              className: 'text-xs',
              render: (row) =>
                <div>
                  {row.quantityOnHand > 0 && (
                    <div className="flex space-x-2 justify-center">
                      <Button
                        disabled={row.remove === 0}
                        className="border-0 bg-transparent"
                        onClick={() => {
                          if (unitItemsForBreakdown > 0) {
                            const tempRelatedItems = [...relatedItems];
                            for (let i = 0; i < tempRelatedItems.length; i++) {
                              if (tempRelatedItems[i].id === row.id) {
                                tempRelatedItems[i].remove -= 1;
                              }
                            }
                            setRelatedItems(tempRelatedItems);
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </Button>
                      <span className="text-lg">{row.remove}</span>
                      <Button
                        disabled={row.remove >= row.quantityOnHand}
                        className="border-0 bg-transparent"
                        onClick={() => {
                          const tempRelatedItems = [...relatedItems];
                          for (let i = 0; i < tempRelatedItems.length; i++) {
                            if (tempRelatedItems[i].id === row.id) {
                              tempRelatedItems[i].remove += 1;
                            }
                          }
                          setRelatedItems(tempRelatedItems);
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </div>
                  )}
                </div>,
            },
            {
              title: t('add'),
              key: 'add',
              align: 'center',
              className: 'text-xs',
              render: (row) =>
                <div className="flex space-x-2 justify-center">
                  <Button
                    disabled={row.add === 0}
                    className="border-0 bg-transparent"
                    onClick={() => {
                      const tempRelatedItems = [...relatedItems];
                      for (let i = 0; i < tempRelatedItems.length; i++) {
                        if (tempRelatedItems[i].id === row.id) {
                          tempRelatedItems[i].add -= 1;
                        }
                      }
                      setRelatedItems(tempRelatedItems);
                    }}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </Button>
                  <span className="text-lg">{row.add}</span>
                  <Button
                    disabled={unitItemsForBreakdown === 0 || getUnitItemsForBreakdown() < row.pack}
                    className="border-0 bg-transparent"
                    onClick={() => {
                      const tempRelatedItems = [...relatedItems];
                      for (let i = 0; i < tempRelatedItems.length; i++) {
                        if (tempRelatedItems[i].id === row.id) {
                          tempRelatedItems[i].add += 1;
                        }
                      }
                      setRelatedItems(tempRelatedItems);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </div>
            }
          ]}
          dataSource={relatedItems}
        />
      </Modal>
    </div>
  );
}

export default ItemHistoryModal;
