import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useJsonToCsv } from 'react-json-csv';
import {
  Button,
  Table,
  Tabs,
  Image,
  Input,
  Avatar,
  notification,
  Tooltip,
  Select,
  Popconfirm,
  DatePicker,
  Card
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import moment from 'moment';
import {
  businessAtom,
  showCreateSharedStoreAtom,
  reloadSharedStoresAtom,
  selectedInventoryItemAtom,
  timezoneAtom
} from '../../../atoms/Atoms';
import api from '../../../api/api';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import AssignSharedStoreModal from './shared-components/AssignSharedStoreModal';
import ItemHistoryModal from './shared-components/ItemHistoryModal';
import { mapInventoryReasonToString, mapInventoryTypeToString, renderDate, thousand, toCurrency } from '../../utils/functions';
import envConfig from '../../../envConfig';
import { dateRangePresets } from '../../utils/utils';

function Inventory() {
  const bottomRowGridStyle = { width: '100%', textAlign: 'center' };
  const { saveAsCsv } = useJsonToCsv();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const setShowCreateSharedStore = useSetRecoilState(showCreateSharedStoreAtom);
  const [reloadSharedStores, setReloadSharedStores] = useRecoilState(reloadSharedStoresAtom);
  const [selectedInventoryItem, setSelectedInventoryItem]
    = useRecoilState(selectedInventoryItemAtom);
  const business = useRecoilValue(businessAtom);
  const [isSearchingInventory, setIsSearchingInventory] = useState(false);
  const [isSearchingReport, setIsSearchingReport] = useState(false);
  const [isLoadingStores, setIsLoadingStores] = useState(false);
  const [lastSearchInventory, setLastSearchInventory] = useState('');
  const [lastSearchReport, setLastSearchReport] = useState('');
  const [currentPageInventory, setCurrentPageInventory] = useState(0);
  const [currentPageReport, setCurrentPageReport] = useState(0);
  const [sizeInventory, setSizeInventory] = useState(20);
  const [sizeReport, setSizeReport] = useState(20);
  const [sorting, setSorting] = useState(0);
  const [productsInventory, setProductsInventory] = useState([]);
  const [movementReport, setMovementReport] = useState({});
  const [totalItemsInSearchInventory, setTotalItemsInSearchInventory] = useState(0);
  const [totalItemsInSearchReport, setTotalItemsInSearchReport] = useState(0);
  const [reportTypeFilter, setReportTypeFilter] = useState('');
  const [sharedStores, setSharedStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState('0');
  const [shouldReset, setShouldReset] = useState({
    inventory: false,
    shared: false,
    report: false,
  });
  const [reportDateRange, setReportDateRange] = useState({
    start: moment().startOf('day').format('YYYY-MM-DD'),
    end: moment().endOf('day').format('YYYY-MM-DD'),
  });
  const timezone = useRecoilValue(timezoneAtom);
  const [loadingDataForExport, setLoadingDataForExport] = useState(false);

  const exportFields = {
    gtin: 'GTIN',
    itemName: t('item'),
    createdAt: t('created_on'),
    typeString: t('type'),
    reasonString: t('reason'),
    amount: t('amount'),
    comments: t('comments'),
    registeredByCompleteName: t('registered_by'),
  };
  const reportColumns = [
    {
      title: 'GTIN',
      dataIndex: 'gtin',
      key: 'gtin',
      align: 'left',
      className: 'text-xs',
      render: (text) =>
        <span>{text}</span>,
    },
    {
      title: t('item'),
      dataIndex: 'itemName',
      key: 'itemName',
      align: 'left',
      className: 'text-xs',
      render: (text) =>
        <span>{text}</span>,
    },
    {
      title: t('created_on'),
      dataIndex: 'createdAt',
      key: 'name',
      align: 'left',
      className: 'text-xs',
      render: (text) =>
        <span>{renderDate(text, timezone)}</span>,
    },
    {
      title: t('type'),
      dataIndex: 'typeString',
      key: 'type',
      align: 'left',
      className: 'text-xs',
      render: (text) =>
        <span>{text}</span>,
    },
    {
      title: t('reason'),
      dataIndex: 'reasonString',
      key: 'reason',
      align: 'left',
      className: 'text-xs',
      render: (text) =>
        <span>{text}</span>,
    },
    {
      title: t('amount'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'left',
      className: 'text-xs',
      render: (text) =>
        <span>{thousand(text)}</span>,
    },
    {
      title: t('comments'),
      dataIndex: 'comments',
      key: 'comments',
      align: 'left',
      className: 'text-xs',
      render: (text) =>
        <span>{text}</span>,
    },
    {
      title: t('registered_by'),
      key: 'registered_by',
      align: 'left',
      className: 'text-xs',
      render: (text) =>
        <span>{text.registeredBy.completeName}</span>,
    }
  ];

  function mapExportReport(tempMovement) {
    return _.map(tempMovement, (m) => ({
      gtin: m.businessItem.item.gtin,
      itemName: (i18n.language === 'en' ?
        (`${m.businessItem.item.brand} ${m.businessItem.item.name} ${m.businessItem.item.description}`).trim() :
        (`${m.businessItem.item.brand} ${m.businessItem.item.nameSpa} ${m.businessItem.item.descriptionSpa}`).trim())
        .replaceAll(',', ' '),
      createdAt: m.createdAt,
      typeString: mapInventoryTypeToString(t, m.type),
      reasonString: mapInventoryReasonToString(t, m.reason),
      amount: m.createdAt,
      comments: m.comments?.replaceAll(',', ' '),
      registeredByCompleteName: m?.registeredBy?.completeName?.replaceAll(',', ' ')
    }));
  }

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function searchProducts(query, page) {
    setIsSearchingInventory(true);
    const payload = {
      Query: query,
      Page: page,
      Size: sizeInventory,
      Sorting: sorting,
      BusinessId: business.id
    };
    api
      .post(
        'inventory/search',
        payload
      )
      .then((response) => {
        if (response.data.success) {
          setIsSearchingInventory(false);
          setTotalItemsInSearchInventory(response.data.data.total);
          setProductsInventory(response.data.data.result);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setIsSearchingInventory(false);
        showMessage(error);
      });
  }

  function mapReportMovement(tempMovement) {
    return _.map(tempMovement, (m) => ({
      ...m,
      gtin: m.businessItem.item.gtin,
      itemName: i18n.language === 'en' ?
        (`${m.businessItem.item.brand} ${m.businessItem.item.name} ${m.businessItem.item.description}`).trim() :
        (`${m.businessItem.item.brand} ${m.businessItem.item.nameSpa} ${m.businessItem.item.descriptionSpa}`).trim(),
      typeString: mapInventoryTypeToString(t, m.type),
      reasonString: mapInventoryReasonToString(t, m.reason),
      registeredByCompleteName: m?.registeredBy?.completeName
    }));
  }

  function searchReport(query, page) {
    setIsSearchingReport(true);
    let tempType = null;
    if (reportTypeFilter !== '') {
      tempType = parseInt(reportTypeFilter, 10);
    }
    const payload = {
      Query: query,
      Page: page,
      Size: sizeReport,
      Type: tempType,
      BusinessId: business?.id,
      StartDate: dayjs(reportDateRange.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      EndDate: dayjs(reportDateRange.end).endOf('day').add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
    };
    api
      .post(
        'inventory/movement/report',
        payload
      )
      .then((response) => {
        setIsSearchingReport(false);
        if (response.data.success) {
          setTotalItemsInSearchReport(response.data.data.total);
          setMovementReport({
            ...response.data.data,
            movement: mapReportMovement(response.data.data.movement)
          });
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setIsSearchingReport(false);
        showMessage(error.message);
      });
  }

  function getExportData() {
    setLoadingDataForExport(true);
    let tempType = null;
    if (reportTypeFilter !== '') {
      tempType = parseInt(reportTypeFilter, 10);
    }
    const payload = {
      Query: lastSearchReport,
      Type: tempType,
      BusinessId: business?.id,
      StartDate: dayjs(reportDateRange.start).add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
      EndDate: dayjs(reportDateRange.end).endOf('day').add(-1 * envConfig.REACT_APP_TIMEZONE_OFFSET, 'hour').format('YYYY-MM-DDTHH:mm:ss.000'),
    };
    api
      .post(
        'inventory/movement/report/all',
        payload
      )
      .then((response) => {
        setLoadingDataForExport(false);
        if (response.data.success) {
          const data = mapExportReport(response.data.data);
          saveAsCsv({
            data,
            fields: exportFields,
            filename: `inventory_movement_${reportDateRange.start}_${reportDateRange.end}`,
          });
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoadingDataForExport(false);
        showMessage(error.message);
      });
  }

  function onSearch(value) {
    setLastSearchInventory(value);
    setCurrentPageInventory(0);
    searchProducts(value, 0);
  }

  function reportSearch(value) {
    setLastSearchReport(value);
    setCurrentPageReport(0);
    searchReport(value, 0);
  }

  function onInventoryTableChange(page, pageSize) {
    if (page - 1 !== currentPageInventory) {
      setCurrentPageInventory(page - 1);
      searchProducts(lastSearchInventory, page - 1);
    } else {
      setSizeInventory(pageSize);
    }
  }

  function onReportTableChange(page, pageSize) {
    if (page - 1 !== currentPageReport) {
      setCurrentPageReport(page - 1);
      searchReport(lastSearchReport, page - 1);
    } else {
      setSizeReport(pageSize);
    }
  }

  function getSharedStores() {
    setIsLoadingStores(true);
    if (reloadSharedStores) {
      setReloadSharedStores(false);
    }
    api
      .get(`inventory/shared/${business?.id}`)
      .then((response) => {
        setIsLoadingStores(false);
        if (response.data.success) {
          setSharedStores(response.data.data.results);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setIsLoadingStores(false);
        showMessage(error);
      });
  }

  function copyInventory(fromStoreId) {
    setLoading(true);
    api
      .post(`inventory/shared/copy/${fromStoreId}/${business?.id}`, null)
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        } else {
          showMessage(t('copy_inventory_successful'));
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  }

  function removeSharedStore(storeId) {
    setLoading(true);
    api
      .post('inventory/shared/remove', {
        BusinessId: business?.id,
        SharedBusinessId: storeId
      })
      .then((response) => {
        setLoading(false);
        if (!response.data.success) {
          showMessage(response.data.error);
        } else {
          showMessage(t('store_removed_successfully'));
          setSharedStores(response.data.data.results);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  }

  if (!business) {
    history.push('/');
    return null;
  }

  useEffect(() => {
    onSearch('');
    getSharedStores();
  }, []);

  useEffect(() => {
    if (selectedTab === '0') {
      onSearch('');
    } else {
      setShouldReset({
        ...shouldReset,
        shared: true,
        report: true
      });
    }
    if (selectedTab === '1') {
      getSharedStores();
    } else {
      setShouldReset({
        ...shouldReset,
        inventory: true,
        report: true
      });
    }
    if (selectedTab === '2') {
      reportSearch('');
    } else {
      setShouldReset({
        ...shouldReset,
        inventory: true,
        shared: true
      });
    }
  }, [business]);

  useEffect(() => {
    if (reloadSharedStores) {
      getSharedStores();
    }
  }, [reloadSharedStores]);

  useEffect(() => {
    onSearch(lastSearchInventory);
  }, [sorting]);

  useEffect(() => {
    if (!selectedInventoryItem) {
      searchProducts(lastSearchInventory, currentPageInventory);
    }
  }, [selectedInventoryItem]);

  useEffect(() => {
    if (shouldReset.inventory && selectedTab === '0') {
      onSearch('');
      setShouldReset({
        ...shouldReset,
        inventory: false
      });
    }
    if (shouldReset.shared && selectedTab === '1') {
      getSharedStores();
      setShouldReset({
        ...shouldReset,
        shared: false
      });
    }
    if (shouldReset.report && selectedTab === '2') {
      reportSearch('');
      setShouldReset({
        ...shouldReset,
        report: false
      });
    }
  }, [selectedTab]);

  useEffect(() => {
    reportSearch(lastSearchReport);
  }, [reportDateRange, reportTypeFilter]);

  return (
    <div>
      <PageHeader
        className="px-0"
        title={t('inventory')}
      />
      <Tabs onChange={(key) => setSelectedTab(key)} activeKey={selectedTab}>
        <Tabs.TabPane tab={t('items')} key="0">
          <div>
            <div className="flex">
              <Input.Search
                key="itemSearch"
                placeholder={t('item_search_placeholder')}
                allowClear
                enterButton={t('search')}
                disabled={isSearchingInventory}
                size="large"
                onSearch={onSearch}
              />
              <Select
                className="mt-1 ml-2"
                style={{ width: 150 }}
                disabled={isSearchingInventory}
                onChange={(value) => setSorting(value)}
                value={sorting}
              >
                <Option value={0}>{t('default_sort')}</Option>
                <Option value={1}>ASC</Option>
                <Option value={2}>DESC</Option>
              </Select>
            </div>
            <Table
              size="small"
              className="mt-2"
              loading={isSearchingInventory}
              bordered
              pagination={{
                pageSize: sizeInventory,
                showSizeChanger: true,
                defaultCurrent: 0,
                current: currentPageInventory + 1,
                total: totalItemsInSearchInventory,
                onChange: onInventoryTableChange
              }}
              columns={[
                {
                  title: 'UPC',
                  key: 'upc',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{text.upc}</span>,
                },
                {
                  title: t('image'),
                  key: 'image',
                  align: 'center',
                  width: 80,
                  className: 'text-xs',
                  render: (text) =>
                    <Image
                      width={50}
                      src={text.itemImage}
                      alt={`${text.brand} ${text.name} ${text.description}`}
                    />,
                },
                {
                  title: t('brand'),
                  key: 'brand',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{text.brand}</span>,
                },
                {
                  title: t('name'),
                  key: 'name',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{text.name}</span>,
                },
                {
                  title: t('description'),
                  key: 'description',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{text.description}</span>,
                },
                {
                  title: t('cost'),
                  key: 'cost',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{toCurrency(text.cost) === '-' ? '$0.00' : toCurrency(text.cost)}</span>,
                },
                {
                  title: t('quantity_on_hand'),
                  key: 'quantityOnHand',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{thousand(text.quantityOnHand)}</span>,
                },
                {
                  title: t('pack'),
                  key: 'pack',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{text.pack}</span>,
                },
                {
                  title: t('actions'),
                  key: 'manage',
                  align: 'center',
                  width: 30,
                  className: 'text-xs',
                  render: (text, item) => (
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        setSelectedInventoryItem(item);
                      }}
                    >
                      {t('manage')}
                    </Button>
                  ),
                },
              ]}
              dataSource={productsInventory}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('shared_inventory')} key="1">
          <div>
            <Button
              className="float-right mb-2"
              size="small"
              type="primary"
              onClick={() => {
                setShowCreateSharedStore(true);
              }}
            >
              {t('add')}
            </Button>
            <Table
              size="small"
              className="mt-2"
              loading={isLoadingStores}
              bordered
              pagination="none"
              columns={[
                {
                  title: t('name'),
                  key: 'name',
                  align: 'left',
                  className: 'text-xs',
                  render: (text) =>
                    <span>{text.name}</span>,
                },
                {
                  title: t('actions'),
                  key: 'manage',
                  align: 'center',
                  width: 30,
                  className: 'text-xs',
                  render: (text, item) => (
                    <div className="flex">
                      <Popconfirm
                        key="copy_inv"
                        title={t('copy_inventory_prompt')}
                        okText={t('yes')}
                        onConfirm={() => {
                          copyInventory(item.id);
                        }}
                      >
                        <Button
                          className="mr-2"
                          loading={loading}
                          size="small"
                          type="primary"
                        >
                          {t('copy_inventory')}
                        </Button>
                      </Popconfirm>
                      <Popconfirm
                        key="remove"
                        title={t('remove_business')}
                        okText={t('yes')}
                        onConfirm={() => {
                          removeSharedStore(item.id);
                        }}
                      >
                        <Button
                          loading={loading}
                          size="small"
                          type="primary"
                          danger
                        >
                          {t('remove')}
                        </Button>
                      </Popconfirm>
                    </div>
                  ),
                },
              ]}
              dataSource={sharedStores}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('movement_history')} key="2">
          <div>
            <dl className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
              <Card
                type="inner"
                title={t('sale')}
                className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
                hoverable
                loading={loading}
              >
                <Card.Grid
                  className="bg-white"
                  hoverable={false}
                  style={bottomRowGridStyle}
                >
                  <span> {thousand(movementReport?.totalSale)}</span>
                </Card.Grid>
              </Card>
              <Card
                type="inner"
                title={t('receiving')}
                className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
                hoverable
                loading={loading}
              >
                <Card.Grid
                  className="bg-white"
                  hoverable={false}
                  style={bottomRowGridStyle}
                >
                  <span> {thousand(movementReport?.totalReceiving)}</span>
                </Card.Grid>
              </Card>
              <Card
                type="inner"
                title={t('adjustment_up')}
                className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
                hoverable
                loading={loading}
              >
                <Card.Grid
                  className="bg-white"
                  hoverable={false}
                  style={bottomRowGridStyle}
                >
                  <span> {thousand(movementReport?.totalAdjustmentUp)}</span>
                </Card.Grid>
              </Card>
              <Card
                type="inner"
                title={t('adjustment_down')}
                className="rounded-xl bg-gradient-to-br from-blue-200 to-white"
                hoverable
                loading={loading}
              >
                <Card.Grid
                  className="bg-white"
                  hoverable={false}
                  style={bottomRowGridStyle}
                >
                  <span> {thousand(movementReport?.totalAdjustmentDown)}</span>
                </Card.Grid>
              </Card>
            </dl>
            <div className="flex mb-2">
              <Button
                type="primary"
                size="small"
                className="ml-auto"
                disabled={isSearchingReport}
                loading={loadingDataForExport}
                icon={<CloudDownloadOutlined />}
                onClick={() => {
                  getExportData();
                }}
              >
                {t('export')}
              </Button>
            </div>
            <div className="flex space-x-2">
              <Input.Search
                key="reportSearch"
                disabled={isSearchingReport || loadingDataForExport}
                placeholder={t('item_search_placeholder')}
                allowClear
                enterButton={t('search')}
                size="large"
                onSearch={reportSearch}
              />
              <RangePicker
                format="YYYY-MM-DD"
                disabled={isSearchingReport || loadingDataForExport}
                value={[dayjs(reportDateRange?.start), dayjs(reportDateRange?.end)]}
                presets={dateRangePresets(t)}
                onChange={(date, dateString) => {
                  setReportDateRange({
                    start: dateString[0],
                    end: dateString[1],
                  });
                }}
              />
              <Select
                className="h-10"
                style={{ width: 200 }}
                disabled={isSearchingReport || loadingDataForExport}
                onChange={(value) => setReportTypeFilter(value)}
                value={reportTypeFilter}
              >
                <Option value="">{t('all')}</Option>
                <Option value="0">{t('sale')}</Option>
                <Option value="1">{t('adjustment_up')}</Option>
                <Option value="2">{t('adjustment_down')}</Option>
                <Option value="3">{t('receiving')}</Option>
              </Select>
            </div>
            <Table
              size="small"
              className="mt-2"
              loading={isSearchingReport}
              bordered
              pagination={{
                pageSize: sizeReport,
                showSizeChanger: true,
                defaultCurrent: 0,
                current: currentPageReport + 1,
                total: totalItemsInSearchReport,
                onChange: onReportTableChange
              }}
              dataSource={movementReport.movement}
              columns={reportColumns}
            />
          </div>
        </Tabs.TabPane>
      </Tabs>
      <AssignSharedStoreModal />
      <ItemHistoryModal />
    </div>
  );
}

export default Inventory;
