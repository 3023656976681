import moment from 'moment';

export const dateRangePresets = (t) => [
  {
    label: t('today'),
    value: () => [moment(), moment()]
  },
  {
    label: t('yesterday'),
    value: () => [moment().add(-1, 'day'), moment().add(-1, 'day')]
  },
  {
    label: t('current_week'),
    value: () => [moment().startOf('week'), moment().endOf('week')]
  },
  {
    label: t('last_week'),
    value: () => [moment().add(-1, 'week').startOf('week'), moment().add(-1, 'week').endOf('week')]
  },
  {
    label: t('current_month'),
    value: () => [moment().startOf('month'), moment().endOf('month')]
  },
  {
    label: t('past_month'),
    value: () => [moment().add(-1, 'months').startOf('month'), moment().add(-1, 'months').endOf('month')]
  },
  {
    label: t('last_2_month'),
    value: () => [moment().add(-1, 'month').startOf('month'), moment().endOf('month')]
  },
  {
    label: t('last_3_month'),
    value: () => [moment().add(-2, 'month').startOf('month'), moment().endOf('month')]
  },
  {
    label: t('last_4_month'),
    value: () => [moment().add(-3, 'month').startOf('month'), moment().endOf('month')]
  },
  {
    label: t('last_6_month'),
    value: () => [moment().add(-5, 'month').startOf('month'), moment().endOf('month')]
  },
  {
    label: t('year_to_date'),
    value: () => [moment().startOf('year'), moment()]
  },
  {
    label: t('last_year'),
    value: () => [moment().add(-1, 'year').startOf('year'), moment().add(-1, 'year').endOf('year')]
  },
];
