import React, { useEffect, useState } from 'react';
import {
  useRecoilState,
  useSetRecoilState
} from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Avatar,
  Tooltip,
  Button,
  notification,
  Table
} from 'antd';
import { Comment } from '@ant-design/compatible';
import arrayMove from 'array-move';
import './drag.css';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  showManageFiltersAtom,
  refreshSearchFiltersAtom,
  showAddFilterAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function ManageFiltersDrawer() {
  const setShowAddFilter = useSetRecoilState(showAddFilterAtom);
  const [refreshSearchFilters, setRefreshSearchFilters] = useRecoilState(refreshSearchFiltersAtom);
  const [showManageFilters, setShowManageFilters] = useRecoilState(showManageFiltersAtom);
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const updateFilters = function () {
    setLoading(true);
    const tempFilters = JSON.parse(JSON.stringify(filters));
    setFilters([]);
    for (let i = 0; i < tempFilters.length; i++) {
      api
        .post(
          `businesses/catalog/filters/${tempFilters[i].id}`,
          {
            Name: tempFilters[i].name,
            Ordering: i
          }
        )
        .then((response) => {
          if (response.data.success) {
            if (i === tempFilters.length - 1) {
              setLoading(false);
              setRefreshSearchFilters(true);
            }
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error);
        });
    }
  };

  const removeFilterClick = function (filter) {
    setLoading(true);
    api
      .delete(`businesses/catalog/filters/${filter.id}`)
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setRefreshSearchFilters(true);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  function onSortEnd(index) {
    const ds = filters;
    if (index.oldIndex !== -1) {
      const newData = arrayMove(
        [].concat(ds),
        index.oldIndex,
        index.newIndex
      ).filter((el) => !!el);
      setFilters(newData);
    } else {
      setFilters(ds);
    }
  }

  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
  ));
  const SortableItem = sortableElement((props) => <tr {...props} />);
  const SortableContainer = sortableContainer((props) => <tbody {...props} />);
  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  const columns = [
    {
      title: t('sort'),
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: t('name'),
      dataIndex: 'name',
      align: 'center',
      className: 'drag-visible',
      render: (text) => (
        <span>{text}</span>
      ),
    },
    {
      title: t('actions'),
      align: 'center',
      className: 'drag-visible',
      render: (row) => (
        <Button
          size="small"
          type="primary"
          danger
          onClick={() => {
            removeFilterClick(row);
          }}
        >
          {t('delete')}
        </Button>
      ),
    }
  ];

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const dataSource = filters;
    if (dataSource) {
      const index = dataSource.findIndex(
        (x) => x.ordering === restProps['data-row-key']
      );
      return <SortableItem index={index} {...restProps} />;
    }
    return null;
  };

  useEffect(() => {
    if (showManageFilters) {
      setLoading(true);
      api
        .get('businesses/catalog/filters')
        .then((response) => {
          if (response.data.success) {
            setLoading(false);
            setFilters(response.data.data.results);
          } else {
            showMessage(response.data.error);
          }
          if (refreshSearchFilters) {
            setRefreshSearchFilters(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error);
        });
    }
  }, [showManageFilters, refreshSearchFilters]);

  return (
    <Drawer
      title={t('manage_filters')}
      placement="right"
      closable
      onClose={() => setShowManageFilters(false)}
      open={showManageFilters}
      width={400}
    >
      <>
        <Button
          onClick={() => { setShowAddFilter(true); }}
          style={{ marginBottom: 10 }}
        >
          {t('add_new_filters')}
        </Button>
        <Table
          size="small"
          bordered
          loading={loading}
          pagination={false}
          dataSource={filters}
          columns={columns}
          rowKey="ordering"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
        <Button
          onClick={() => { updateFilters(); }}
          type="primary"
          style={{
            float: 'right',
            marginTop: 10
          }}
        >
          {t('update')}
        </Button>
      </>
    </Drawer>
  );
}

export default ManageFiltersDrawer;
