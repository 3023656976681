import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import ReactPlayer from 'react-player';
import arrayMove from 'array-move';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Tabs,
  Image,
  Drawer,
  Button,
  Tooltip,
  notification,
  Avatar
} from 'antd';
import { Comment } from '@ant-design/compatible';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import {
  MenuOutlined
} from '@ant-design/icons';
import moment from 'moment';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import {
  setSelectedMasterItemAtom,
  showItemMediaAtom,
  showAddItemMediaAtom,
  refreshItemMediaAtom
} from '../../../../atoms/Atoms';
import api from '../../../../api/api';

function ManageItemMedia() {
  const { TabPane } = Tabs;
  const selectedMasterItem = useRecoilValue(setSelectedMasterItemAtom);
  const setShowAddItemMedia = useSetRecoilState(showAddItemMediaAtom);
  const [refreshItemMedia, setRefreshItemMedia] = useRecoilState(refreshItemMediaAtom);
  const [showItemMedia, setShowItemMedia] = useRecoilState(showItemMediaAtom);
  const [loading, setLoading] = useState(false);
  const [itemMedia, setItemMedia] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [videos, setVideos] = useState([]);
  const { t } = useTranslation();

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const getItemMedia = function () {
    setLoading(true);
    api
      .get(`businesses/catalog/media/${selectedMasterItem.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setItemMedia(response.data.data.results);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  const updatePictures = function () {
    setLoading(true);
    const tempList = JSON.parse(JSON.stringify(pictures));
    for (let i = 0; i < tempList.length; i++) {
      api
        .post(
          `businesses/catalog/media/${tempList[i].id}`,
          {
            Ordering: i,
            Type: tempList[i].type,
            Url: tempList[i].url,
            ThumbnailUrl: tempList[i].thumbnailUrl,
          }
        )
        .then((response) => {
          if (response.data.success) {
            if (i === tempList.length - 1) {
              setLoading(false);
            }
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error);
        });
    }
  };

  const updateVideos = function () {
    setLoading(true);
    const tempList = JSON.parse(JSON.stringify(videos));
    for (let i = 0; i < tempList.length; i++) {
      api
        .post(
          `businesses/catalog/media/${tempList[i].id}`,
          {
            Ordering: i,
            Type: tempList[i].type,
            Url: tempList[i].url,
            ThumbnailUrl: tempList[i].thumbnailUrl,
          }
        )
        .then((response) => {
          if (response.data.success) {
            if (i === tempList.length - 1) {
              setLoading(false);
            }
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          showMessage(error);
        });
    }
  };

  const removePictureClick = function (picture) {
    setLoading(true);
    const idToRemove = picture.id;
    api
      .post(
        `businesses/catalog/removemedia/${picture.id}`,
        { }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          const temp = _.filter(pictures, (r) => r.id !== idToRemove);
          setPictures(temp);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  const removeVideoClick = function (video) {
    setLoading(true);
    const idToRemove = video.id;
    api
      .post(
        `businesses/catalog/removemedia/${video.id}`,
        { }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          const temp = _.filter(videos, (r) => r.id !== idToRemove);
          setVideos(temp);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showMessage(error);
      });
  };

  function onSortPicturesEnd(index) {
    const ds = pictures;
    if (index.oldIndex !== -1) {
      const newData = arrayMove(
        [].concat(ds),
        index.oldIndex,
        index.newIndex
      ).filter((el) => !!el);
      setPictures(newData);
    } else {
      setPictures(ds);
    }
  }

  function onSortVideosEnd(index) {
    const ds = videos;
    if (index.oldIndex !== -1) {
      const newData = arrayMove(
        [].concat(ds),
        index.oldIndex,
        index.newIndex
      ).filter((el) => !!el);
      setVideos(newData);
    } else {
      setVideos(ds);
    }
  }

  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
  ));
  const SortableItem = sortableElement((props) => <tr {...props} />);
  const SortableContainer = sortableContainer((props) => <tbody {...props} />);
  const DraggablePictureContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortPicturesEnd}
      {...props}
    />
  );
  const DraggableVideoContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortVideosEnd}
      {...props}
    />
  );

  const DraggablePictureBodyRow = ({ className, style, ...restProps }) => {
    const dataSource = pictures;
    if (dataSource) {
      const index = dataSource.findIndex(
        (x) => x.ordering === restProps['data-row-key']
      );
      return <SortableItem index={index} {...restProps} />;
    }
    return null;
  };

  const DraggableVideoBodyRow = ({ className, style, ...restProps }) => {
    const dataSource = pictures;
    if (dataSource) {
      const index = dataSource.findIndex(
        (x) => x.ordering === restProps['data-row-key']
      );
      return <SortableItem index={index} {...restProps} />;
    }
    return null;
  };

  useEffect(() => {
    if (showItemMedia) {
      getItemMedia();
    }
  }, [showItemMedia]);

  useEffect(() => {
    if (refreshItemMedia) {
      getItemMedia();
      setRefreshItemMedia(false);
    }
  }, [refreshItemMedia]);

  useEffect(() => {
    if (itemMedia) {
      if (itemMedia.length === 0) {
        setPictures([]);
        setVideos([]);
      } else {
        setPictures(_.filter(itemMedia, (m) => m.type === 0));
        setVideos(_.filter(itemMedia, (m) => m.type === 1));
      }
    }
  }, [itemMedia]);

  return (
    <Drawer
      title={t('item_media')}
      placement="right"
      closable
      onClose={() => setShowItemMedia(false)}
      open={showItemMedia}
      width={400}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Pictures" key="1">
          <Button
            onClick={() => {
              setShowAddItemMedia({
                type: 0
              });
            }}
            style={{ marginBottom: 10 }}
          >
            {t('add_new_picture')}
          </Button>
          <Table
            size="small"
            bordered
            loading={loading}
            pagination={false}
            dataSource={pictures}
            columns={[
              {
                title: t('sort'),
                dataIndex: 'sort',
                width: 30,
                className: 'drag-visible',
                render: () => <DragHandle />,
              },
              {
                title: t('image'),
                align: 'center',
                className: 'drag-visible',
                render: (row) => (
                  <Image
                    height={100}
                    width={100}
                    src={row.url}
                    alt={row.url}
                  />
                ),
              },
              {
                title: t('actions'),
                align: 'center',
                className: 'drag-visible',
                render: (row) => (
                  <Button
                    size="small"
                    type="primary"
                    danger
                    onClick={() => {
                      removePictureClick(row);
                    }}
                  >
                    {t('delete')}
                  </Button>
                ),
              }
            ]}
            rowKey="ordering"
            components={{
              body: {
                wrapper: DraggablePictureContainer,
                row: DraggablePictureBodyRow,
              },
            }}
          />
          <Button
            onClick={() => { updatePictures(); }}
            type="primary"
            style={{
              float: 'right',
              marginTop: 10
            }}
          >
            {t('update')}
          </Button>
        </TabPane>
        <TabPane tab="Videos" key="2">
          <Button
            onClick={() => {
              setShowAddItemMedia({
                type: 1
              });
            }}
            style={{
              marginBottom: 10
            }}
          >
            {t('add_new_video')}
          </Button>
          <Table
            size="small"
            bordered
            loading={loading}
            pagination={false}
            dataSource={videos}
            columns={[
              {
                title: t('sort'),
                dataIndex: 'sort',
                width: 30,
                className: 'drag-visible',
                render: () => <DragHandle />,
              },
              {
                title: 'Video',
                align: 'center',
                className: 'drag-visible',
                render: (row) => (
                  <ReactPlayer
                    controls
                    height={100}
                    width={100}
                    url={row.url}
                  />
                ),
              },
              {
                title: 'Thumbnail',
                align: 'center',
                className: 'drag-visible',
                render: (row) => (
                  <Image
                    height={100}
                    width={100}
                    src={row.thumbnailUrl}
                    alt={row.thumbnailUrl}
                  />
                ),
              },
              {
                title: t('actions'),
                align: 'center',
                className: 'drag-visible',
                render: (row) => (
                  <Button
                    type="primary"
                    danger
                    onClick={() => {
                      removeVideoClick(row);
                    }}
                  >
                    {t('delete')}
                  </Button>
                ),
              }
            ]}
            rowKey="ordering"
            components={{
              body: {
                wrapper: DraggableVideoContainer,
                row: DraggableVideoBodyRow,
              },
            }}
          />
          <Button
            onClick={() => { updateVideos(); }}
            type="primary"
            style={{
              float: 'right',
              marginTop: 10
            }}
          >
            {t('update')}
          </Button>
        </TabPane>
      </Tabs>
    </Drawer>
  );
}

export default ManageItemMedia;
